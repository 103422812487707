<template>
    <div>
        <el-button type="primary" class="w-full" :disabled="!userCan('create emails')" @click="composeNewEmail">
            {{ $t('communication.compose_new_email') }}
        </el-button>

        <div class="mt-4 pt-4 border-t">
            <div
                class="flex justify-between items-center py-2 px-4 hover:bg-red-100 cursor-pointer rounded transition"
                :class="{'bg-red-100': activeMenu == 'inbox_from_clients'}"
                @click="changeInbox('inbox_from_clients')"
            >
                <div class="flex items-center">
                    <fa-icon :icon="['far', 'inbox-in']" fixed-width class="mr-4 text-gray-600" />
                    <p class="text-sm">
                        {{ $t('communication.inbox_from_clients') }}
                    </p>
                </div>
                <!-- <span class="text-xs text-white py-1 px-2 rounded-md bg-green-400">
                    24
                </span> -->
            </div>
            <div
                class="flex justify-between items-center py-2 px-4 hover:bg-red-100 cursor-pointer rounded transition"
                :class="{'bg-red-100': activeMenu == 'inbox_from_others'}"
                @click="changeInbox('inbox_from_others')"
            >
                <div class="flex items-center">
                    <fa-icon :icon="['far', 'inbox-in']" fixed-width class="mr-4 text-gray-600" />
                    <p class="text-sm">
                        {{ $t('communication.inbox_from_others') }}
                    </p>
                </div>
                <!-- <span class="text-xs text-white py-1 px-2 rounded-md bg-green-400">
                    24
                </span> -->
            </div>
            <div
                class="flex justify-between items-center py-2 px-4 hover:bg-red-100 cursor-pointer rounded transition mt-3"
                :class="{'bg-red-100': activeMenu == 'sent'}"
                @click="changeInbox('sent')"
            >
                <div class="flex items-center">
                    <fa-icon :icon="['far', 'inbox-out']" fixed-width class="mr-4 text-gray-600" />
                    <p class="text-sm">
                        {{ $t('communication.sent') }}
                    </p>
                </div>
                <!-- <span class="text-xs text-white py-1 px-2 rounded-md bg-green-400">
                    24
                </span> -->
            </div>
            <div
                class="flex justify-between items-center py-2 px-4 hover:bg-red-100 cursor-pointer rounded transition mt-3"
                :class="{'bg-red-100': activeMenu == 'deleted'}"
                @click="changeInbox('deleted')"
            >
                <div class="flex items-center">
                    <fa-icon :icon="['fas', 'trash']" fixed-width class="mr-4 text-gray-600" />
                    <p class="text-sm">
                        {{ $t('communication.deleted') }}
                    </p>
                </div>
                <!-- <span class="text-xs text-white py-1 px-2 rounded-md bg-green-400">
                    24
                </span> -->
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            activeMenu: 'inbox',
        };
    },

    methods: {
        composeNewEmail() {
            this.$emit('newEmail');
        },

        changeInbox(inbox) {
            this.activeMenu = inbox;
            this.$emit('inboxClicked', inbox);
        },
    },
};
</script>
